@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers.scss' as *;

.debug {
  background-color: rgba(255, 255, 255, 95%);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 60%);
  color: var(--thc-color-primary-black);
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.1s linear;
  width: 40vw;
  z-index: 100;

  &.open {
    transform: translate3d(0, 0, 0);
  }

  .scroll {
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: scroll;
    padding: $marginGeneric * 2;
  }

  table {
    width: 100%;

    th {
      font-weight: bold;
    }

    tr.small {
      font-size: $fontSize;
      vertical-align: top;
    }
  }

  .toggle {
    align-items: center;
    background-color: rgba(255, 255, 255, 95%);
    border-radius: 4px 0 0 4px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 60%);
    -webkit-clip-path: polygon(-10px -10px, 100% -10px, 100% calc(100% + 10px), -10px calc(100% + 10px));
    clip-path: polygon(-10px -10px, 100% -10px, 100% calc(100% + 10px), -10px calc(100% + 10px));
    cursor: pointer;
    display: flex;
    height: $marginGeneric * 5;
    justify-content: center;
    left: $marginGeneric * -5;
    position: absolute;
    top: 5%;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: $marginGeneric * 5;
  }
}
