@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.vehicleCenterHeader {
  background-color: thc-color.$primary-yellow;
  padding-bottom: thc-core.$space9;
  text-align: center;

  .logo {
    align-items: center;
    display: flex;
    height: thc-core.$space9;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    z-index: 2;

    a {
      border-bottom: 0;
    }
  }

  .innerHeader {
    display: grid;
    gap: thc-core.$space3;
    grid-template-columns: auto;
    justify-items: center;
    margin-top: thc-core.$space;
    padding-bottom: 0;
    padding-top: 0;
    place-items: center;
  }

  .estimated {

    @include thc-typography.regular;

    padding-top: 0;
  }

  .offer {

    @include thc-typography.hero;

    display: block;
    margin: 0;
    padding: 0;
  }

  p {

    @include thc-typography.heading6;

    margin-bottom: 0;
    padding-bottom: 0;

  }
}
