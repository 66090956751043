@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.sections {
  display: flex;
  flex-direction: column;
  gap: thc-core.$space4;

  .section {
    display: flex;
    flex-direction: column;
    gap: thc-core.$space2;
    max-width: thc-core.$space2 * 34.25; // 8 * 34.25 = 274px
    text-align: left;

    h1 {

      @include thc-typography.heading7;

    }

    p {

      @include thc-typography.small;

      padding: 0;

    }
  }

}
