@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
$background: rgba(29, 29, 27, 50%);

.component {
  left: 0;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  z-index: 2;

  &.disabled {
    pointer-events: none;

    .photoButton:not(.activeButton) {
      opacity: 0.08;
      transform: scale(0.9);
    }
  }

  &.hideOverlay {

    .outlines {
      opacity: 0;
    }
  }

  .videoOverlay {
    display: none;
    height: 100%;
    left: var(--sidebar-width, 16.25%);
    pointer-events: none;
    position: absolute;
    right: var(--sidebar-width, 16.25%);
    top: 0;
    z-index: 4;

    &.isActive {
      display: block;
      pointer-events: all;
    }
  }

  .skipTooltip {
    top: calc(50% - thc-core.$space3);
  }

  .sideBar {
    background-color: $background;
    bottom: 0%;
    min-width: thc-core.$space * 14;
    position: absolute;
    top: 0%;
    width: 16.25%; // stylelint-disable declaration-block-no-duplicate-properties
    width: var(--sidebar-width);

    &.sidebarLeft {
      left: 0;
    }

    &.sidebarRight {
      right: 0;
    }
  }

  .saveButton {

    @include large;

    align-items: center;
    background-color: transparent;
    border: 0;
    color: thc-color.$primary-white;
    display: flex;
    left: 50%;
    margin-bottom: 0;
    min-height: thc-core.$space6;
    outline: none;
    padding: thc-core.$space;
    position: absolute;
    top: thc-core.$space5;
    touch-action: manipulation;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  .helpButton {
    bottom: thc-core.$space4;
    height: thc-core.$space7;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: thc-core.$space7;

    --thc-icon-accent-fill: #{$white};
  }

  .photoButton {
    background-color: transparent;
    border: 2px solid #fff;
    border-radius: 100%;
    cursor: pointer;
    height: thc-core.$space * 9;
    left: calc(50% - 36px);
    outline: none;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
    top: calc(50% - 36px);
    touch-action: manipulation;
    transition: opacity 0.3s linear, transform 0.3s linear;
    width: thc-core.$space * 9;

    &.disabled {
      opacity: 0.08;
      pointer-events: none;
    }

    &::before {
      background-color: #fff;
      border-radius: 100%;
      content: '';
      display: block;
      height: calc(100% - 4px);
      left: 2px;
      position: absolute;
      top: 2px;
      transition: transform 0.1s linear;
      width: calc(100% - 4px);
    }

    @media (pointer: coarse) {

      &.activeButton::before {
        pointer-events: none;
        transform: scale(0.9);
      }
    }
  }

  .categoryLabel {
    align-items: center;
    background-color: rgba(29, 29, 27, 75%);
    border-radius: thc-core.$space * 2.5;
    bottom: thc-core.$space4;
    color: thc-color.$primary-white;
    display: flex;
    height: thc-core.$space6;
    justify-content: center;
    left: 50%;
    padding: 0 thc-core.$space3;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);
  }

  .outlines {
    margin: 0 auto;
    transition: opacity 0.5s linear;
    width: var(--overlay-bg-size-width);
  }
}

.flash {
  background-color: #000;
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;

  &.active {
    animation-duration: 0.2s;
    animation-name: flash;

    @keyframes flash {

      from {
        opacity: 0;
      }

      40% {
        opacity: 1;
      }

      60% {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }
}

.skip {
  transition: opacity 0.2s linear;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
