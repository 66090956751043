@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers.scss' as *;

$iPhone5PortraitWidth: 320px;
$iPhone6PortraitHeight: 553px;
$iPhoneXPortraitHeight: 635px;
$iPhoneXPortraitHeightSwipe: 749px;
$iPhone5LandscapeWidth: 568px;
$iPhone6LandscapeWidth: 667px;
$iPhone6PlusLandscapeWidth: 736px;
$iPhoneXLandscapeWidth: 812px;
$iPhone5Landscape: $iPhone5LandscapeWidth + 1;
$iPhone6Portrait: $iPhone6PortraitHeight - 1;

.phoneNumber {
  border-bottom: 1px solid rgba(thc-color.$primary-black, 30%);
  white-space: nowrap;
}

.component {
  align-items: center;
  background-color: var(--thc-color-primary-yellow);
  color: var(--thc-color-primary-black);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  left: 0;
  max-width: 100vw;
  min-height: 80vh;
  padding: $marginGeneric * 4 $marginGeneric * 3;
  width: 100%;
  z-index: 999;

  @media (orientation: landscape) {
    padding: 0;
  }

  @media (orientation: landscape) and (max-width: $iPhone5LandscapeWidth) {
    padding: $marginGeneric $marginGeneric * 7;
  }
}

.logo {
  width: $marginGeneric * 17;
}

.errorIcon {

  img {
    align-self: center;
    width: $marginGeneric * 16;

    @media (orientation: landscape) and (min-width: $iPhone6LandscapeWidth) {
      width: $marginGeneric * 21;
    }

    @media (orientation: landscape) and (min-width: $iPhone6PlusLandscapeWidth) {
      width: $marginGeneric * 26;
    }

    @media (orientation: portrait) and (min-height: $iPhoneXPortraitHeight) {
      width: $marginGeneric * 18;
    }
  }
}

.marked {
  font-weight: 600;
}

.buttonWrapper {

  a {
    border: 0 !important;

    @media (orientation: landscape) and (max-width: $iPhone5Landscape) {
      display: flex;
      justify-content: center;
    }
  }

  button {

    svg {
      fill: #fff;
      margin-right: $marginGeneric;
    }

    span {
      display: flex;

      img {
        margin-right: $marginGeneric;
      }
    }
  }
}

.wrapper {
  align-content: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  @media (orientation: landscape) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  section {
    align-content: space-around;
    display: flex;
    min-width: $marginGeneric * 21;

    &:first-child {
      align-content: center;
      justify-content: center;

      @media (orientation: landscape) {
        width: 50%;
      }

      @media (orientation: portrait) {
        margin-bottom: $marginGeneric * 4;
      }

      @media (orientation: portrait) and (max-height: $iPhone6Portrait) {
        display: none;
      }

      @media (orientation: landscape) and (max-width: $iPhone5LandscapeWidth) {
        display: none;
      }

      @media (orientation: landscape) and (max-width: $iPhone6LandscapeWidth) {
        min-width: $marginGeneric * 10;
      }

      @media (orientation: landscape) and (min-width: $iPhoneXLandscapeWidth) {
        margin-bottom: 0;
      }
    }

    &:last-child {
      flex-direction: column;

      @media (orientation: landscape) and (min-width: $iPhone6LandscapeWidth) {
        margin-left: $marginGeneric * 7;
        max-width: calc(50% - $marginGeneric * 7);
      }

      @media (orientation: landscape) and (min-width: $iPhone6PlusLandscapeWidth) {
        margin-top: $marginGeneric * 4;
      }

      h1 {

        @include title3;

        margin-bottom: $marginGeneric * 3;
        text-align: center;

        @media (orientation: landscape) and (min-width: $iPhone6LandscapeWidth) {
          text-align: left;
        }

        @media (orientation: landscape) and (max-width: $iPhone6LandscapeWidth) {
          margin-bottom: $marginGeneric * 2;
        }

        @media (orientation: portrait) and (max-width: $iPhone5PortraitWidth) {
          margin-bottom: $marginGeneric * 2;
        }
      }

      p {
        text-align: center;

        @media (orientation: landscape) and (min-width: $iPhone6LandscapeWidth) {
          text-align: left;
        }

        @media (orientation: landscape) and (max-width: $iPhone6LandscapeWidth) {
          padding-bottom: $marginGeneric !important;
        }
      }
    }
  }
}
