@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.box {
  border-radius: thc-core.$space-16;
  border: 1px solid var(--thc-component-border);
  margin: 0 auto thc-core.$space4;
  max-width: thc-core.$space * 64;
  overflow: hidden;
  position: relative;
  z-index: 2;

  &:last-child {
    margin-bottom: thc-core.$space2;

  }

  .actionButton {
    max-width: thc-core.$space * 30;
    width: 100%;
  }

  .linkButton {
    align-items: center;
    border: none;
    display: flex;

    &::before {
      content: '';
      height: 100%;
      inset: 0;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }
}

.boxInner {
  align-items: center;
  background-color: thc-color.$primary-white;
  display: flex;
  justify-content: space-between;
  padding: thc-core.$space4;
}

.infoBox {
  flex: 1 1 100%;
  padding-right: 0;

  .title {

    @include thc-typography.heading6;

    margin-bottom: thc-core.$space;

    &:only-child {
      margin: thc-core.$space * 1.5 0;
    }
  }

  .progress {

    @include thc-typography.regular;

    color: thc-color.$light-grey-1;
    display: flex;

    &.started {
      color: thc-color.$primary-black;
    }
  }

  .icon {
    align-items: center;
    display: flex;
    margin-right: thc-core.$space;
  }

  .tickIcon {
    --thc-icon-fill: #{thc-color.$primary-black};
  }

  .crossCircleIcon {
    --thc-icon-fill: var(--thc-semantic-error);
  }

  .loading {
    --thc-icon-fill: #{thc-color.$primary-black};
  }
}

.iconBox {
  align-items: center;
  display: flex;
  margin-right: thc-core.$space3;

  svg {
    height: thc-core.$space7;
    width: thc-core.$space7;
  }
}

.progressBar {
  background-color: thc-color.$light-grey-3;
  height: thc-core.$space;

  .progressLine {
    background-color: thc-color.$primary-black;
    height: 100%;
    width: 25%;
  }

  .retakeProgressLine {
    background-color: thc-color.$light-grey-1;
    height: 100%;
    width: 100%;
  }
}
