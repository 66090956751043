@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.photosHubHeader {
  height: auto;
  min-height: thc-core.$space * 29;
}

.main {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - thc-core.$component-height-large);
}

.content {
  flex-grow: 1;
}

.messagingCardSection {
  margin: 0 auto thc-core.$space4;
  max-width: thc-core.$space * 64;
  position: relative;
  transform: translateY(thc-core.$space * -7);
  z-index: 2;
}

.polaroidCamera {
  right: 0;
}

.carKey {

  @media screen and (orientation: landscape) {
    right: 0;
  }
}

.section {
  transform: translateY(thc-core.$space * -7);
}
