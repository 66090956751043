@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.dialog {
  margin: 0 !important;
  max-width: unset !important;

  & > div {
    margin: 0 !important;
    max-width: unset !important;
    padding: 0 !important;

    @include minMobile {
      overflow: visible !important;
    }
  }

  .content {

    h2 {

      @include title5;

      margin-bottom: $marginGeneric * 3;
      text-align: center;
      user-select: none;
    }
  }
}
