@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  background-color: #000;
  /* stylelint-disable declaration-block-no-duplicate-properties */
  height: 100vh;
  height: 100dvh;
  /* stylelint-enable declaration-block-no-duplicate-properties */
  width: 100%;

  .errorBox {
    color: thc-color.$primary-white;
    left: 50%;
    padding: thc-core.$space4;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
