@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use 'sass:color' as sasscolor;

@use '~@THC/styles/theme/theme-vars.scss' as themeVars;

.container.container {
  display: flex;
  justify-content: center;

  --thc-button-secondary-border-color: #{thc-color.$dark-grey-3};

  button {
    --button-color: #{thc-color.$primary-white};
    --button-background-color: transparent;
  }
}

.overlay {
  background-color: sasscolor.change(thc-color.$primary-black, $alpha: 0.5);
  inset: 0;
  opacity: 0; // Start with an invisible overlay
  position: fixed;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear; // Delay the visibility transition
  visibility: hidden; // Initially hidden
  z-index: 110;

  &.isOpen {
    opacity: 1; // Fully visible when open
    transition: visibility 0s linear 0s, opacity 0.3s linear; // No delay when becoming visible
    visibility: visible; // Make visible when open
  }
}

.helpPanel {
  background-color: thc-color.$dark-grey-1;
  border: 1px solid thc-color.$dark-grey-2;
  border-bottom-left-radius: thc-core.$border-radius;
  border-right: none;
  border-top-left-radius: thc-core.$border-radius;
  color: thc-color.$primary-white;
  contain: content;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: right;
  top: 0;
  transform: translate3d(100%, 0, 0);
  transition: 0.3s transform ease-out;
  width: thc-core.$space * 46.25;
  z-index: 120;

  [data-theme='dark'] {

    @include themeVars.getThemeVars('dark');
  }

  &.isOpen {
    pointer-events: all;
    transform: translate3d(0, 0, 0);
  }

  .close {
    background-color: thc-color.$dark-grey-1;
    border-radius: thc-core.$border-radius;
    display: inline-flex;
    justify-content: flex-end;
    padding: 0 thc-core.$space2;
    position: sticky;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }

  .children {
    padding: 0 thc-core.$space4 thc-core.$space4;
  }

  p {

    @include thc-typography.small;

    text-align: left;
  }

  figure {
    margin: 0;
    position: relative;

    img {
      border-radius: thc-core.$border-radius;
      display: block;
    }

    figcaption {

      @include thc-typography.caption;

      background-color: sasscolor.change(thc-color.$dark-grey-1, $alpha: 0.8);
      border-bottom-left-radius: thc-core.$border-radius;
      border-bottom-right-radius: thc-core.$border-radius;
      bottom: 0;
      left: 0;
      padding: thc-core.$space;
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
}
