@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '~@THC/styles/modifiers';

.messagingCard {
  background-color: thc-color.$secondary-light-blue;
  border-radius: thc-core.$border-radius;
  display: flex;
  margin-bottom: thc-core.$space3;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    border-radius: thc-core.$border-radius;
    box-shadow: 0 0 0 1px thc-color.$darken5 inset;
    content: '';
    display: block;
    inset: 0;
    position: absolute;
  }

  .text {
    flex: 1;
    padding: thc-core.$space3;

    @media screen and (min-width: thc-breakpoints.$breakpointMobile) {
      padding: thc-core.$space4;
    }
  }

  .image {
    position: relative;
    width: 35%;

    > svg,
    > img {
      max-width: unset;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .header {

    @include thc-typography.heading6;

  }

  .description {

    @include thc-typography.small;

    margin-top: thc-core.$space1;
    padding: 0;
  }

  .action {
    --icontext-color: #{thc-color.$primary-black};

    display: block;
    margin-top: thc-core.$space * 1.5;
  }
}

a.messageCardLink {
  position: relative;
  width: 100%;

  &:focus-visible {

    @include modifiers.focusRing;
  }

  :root & {
    border-bottom: none;
  }
}
