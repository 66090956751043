@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.container {
  position: relative;

  --thc-icon-accent-fill: #{thc-color.$primary-white};
}

.skipped {
  --thc-input-file-bg-icon-opacity: 0.4;
}

.noDescClick.noDescClick.noDescClick > div > div > div {
  pointer-events: none;
}

.button {
  opacity: 0;
  transition: opacity 0.8s ease-out;
}

.buttonFadeIn {
  opacity: 1;
}

.buttonFadeOut {
  opacity: 0;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 1;
  }

  17.4%,
  82.6% { // 800ms/4600ms = 17.4% & 3800ms/4600ms = 82.6%
    opacity: 0;
  }
}

.descriptionFadeInFadeOut {
  animation: fadeInOut 4.6s forwards;
}

@keyframes slideDown {

  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(thc-core.$space3 - 4);
  }
}

.slideDown {
  animation-delay: 3s;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-name: slideDown;
  animation-timing-function: ease-out;
}

.slideUp {
  animation-delay: 3s;
  animation-direction: reverse;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-name: slideDown;
  animation-timing-function: ease-out;
  transform: translateY(thc-core.$space3 - 4);
}

@keyframes slideDownFadeOut {

  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(thc-core.$space3 - 4);
  }
}

.slideDownFadeOut {
  animation-delay: 3s;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-name: slideDownFadeOut;
  animation-timing-function: ease-out;
}

.slideUpFadeIn {
  animation-delay: 3s;
  animation-direction: reverse;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-name: slideDownFadeOut;
  animation-timing-function: ease-out;
  opacity: 0;
  transform: translateY(thc-core.$space3 - 4);
}

@keyframes slideDownDone {

  from {
    transform: translateY(- thc-core.$space3 + 4);
  }

  to {
    transform: translateY(0);
  }
}

.slideDownDone {
  animation-delay: 1s;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-name: slideDownDone;
  animation-timing-function: ease-out;
  transform: translateY(- thc-core.$space3 + 4);
}
