@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
$background: linear-gradient(180deg, rgba(thc-color.$primary-black, 0%) 0%, rgba(thc-color.$primary-black, 75%) 100%);

.items {
  display: grid;
  grid-gap: thc-core.$space5;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: thc-breakpoints.$breakpointMobile) {
    grid-gap: thc-core.$space5 thc-core.$space3;
    grid-template-columns: 1fr 1fr;
  }
}

.damage_tyres {
  grid-gap: thc-core.$space4;

  @media screen and (min-width: thc-breakpoints.$breakpointMobile) {
    grid-template-columns: 1fr;
    max-width: thc-core.$space * 64;
  }

  &.isApproved {
    grid-gap: thc-core.$space3;
  }
}

.item {
  width: 100%;

  &.disabled {
    position: relative;

    label {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &:empty {
    display: none;
  }

  .overlay {
    align-items: center;
    background-color: rgba(thc-color.$primary-black, 75%);
    border-radius: thc-core.$border-radius;
    color: thc-color.$primary-white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 0 thc-core.$space4;
    position: absolute;
    width: 100%;
    z-index: 2;

    span {

      @include thc-typography.heading6;

      text-align: center;
    }
  }

  .image {
    display: flex;
    height: auto;
    margin: 0;
    position: relative;

    img {
      border-radius: thc-core.$border-radius thc-core.$border-radius 0 0;
      height: auto;
      width: 100%;
    }

    &.noCheckbox {

      figcaption {
        border-radius: 0 0 thc-core.$border-radius thc-core.$border-radius;
      }

      img {
        border-radius: thc-core.$border-radius;
      }
    }

  }

  .label {
    align-items: center;
    background: $background;
    bottom: 0;
    color: thc-color.$primary-white;
    display: flex;
    gap: thc-core.$space3;
    justify-content: space-between;
    left: 0;
    padding: thc-core.$space3;
    position: absolute;
    width: 100%;

    .desc {

      @include thc-typography.heading6;

      align-items: flex-start;
      display: flex;
      flex-direction: column;
      text-size-adjust: 100%;
    }

    .isApproved {
      --thc-font-color: #{thc-color.$primary-white};
      --thc-icon-fill: var(--thc-semantic-success);

      > div {
        font-weight: normal;
      }
    }

    .locked {
      align-items: center;
      display: flex;
      height: thc-core.$space7;
      justify-content: center;
      margin: 0;
      padding: 0;
      width: thc-core.$space7;
    }

  }

  .inputContainer {
    background-color: thc-color.$primary-white;
    border-radius: thc-core.$border-radius;
    box-shadow: 0 0 thc-core.$space3 0 rgba(thc-color.$primary-black, 25%);
    color: thc-color.$primary-white;

    > label {
      --inline-toggle-box-shadow: none;
      --inline-toggle-border-radius: 0 0 #{thc-core.$space1} #{thc-core.$space1};
    }
  }
}

.inputDisabled {
  pointer-events: none;
}
