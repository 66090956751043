@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use 'sass:color' as sass-color;

$iphone-se-height: 375px;
$iphone-se-width: 580px;

.container {
  align-items: center;
  background-color: thc-color.$primary-black;
  contain: content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  overflow: hidden;
  padding: thc-core.$space3 thc-core.$space4 thc-core.$space4;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  @media (orientation: landscape) and (max-width: $iphone-se-width) {
    padding: thc-core.$space3;
  }

  @include thc-breakpoints.minTablet {
    padding: thc-core.$space5 thc-core.$space7;
  }

  &.active {

    .plusIcon {
      opacity: 0;
      pointer-events: none;
    }

    path {
      stroke: thc-color.$dark-grey-2;
    }
  }
}

.plusIcon {
  align-items: center;
  background-color: thc-color.$primary-black;
  border: 0;
  border-radius: 50%;
  color: thc-color.$primary-white;
  display: flex;
  height: thc-core.$space5;
  justify-content: center;
  opacity: 1;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: opacity 0.25s ease-in;
  width: thc-core.$space5;
  z-index: 10;
}

.carBox {
  margin-top: auto;
  max-width: 100%;
  position: relative;
  transform: scale(var(--car-scale, 1)) translate(var(--car-translate-x, 0), var(--car-translate-y, 0));
  transition: transform 0.4s ease-in-out;

  > svg {
    height: thc-core.$space * 29.5;
    max-width: 100%;
    width: auto;

    @media (orientation: landscape) and (max-height: $iphone-se-height) {
      height: thc-core.$space * 23;
    }

    path {
      fill: transparent;
      transition: fill 0.3s ease-in, stroke 0.3s ease-in;

      &:not([data-panel]),
      &[id$='windscreen'] {
        stroke: thc-color.$dark-grey-2;
      }

      &.activePanel,
      &.activePanel[id$='windscreen'] {
        fill: sass-color.change(thc-color.$primary-black, $alpha: 0.6);
        stroke: thc-color.$primary-white;
      }
    }
  }
}

.point {
  background-color: thc-color.$primary-yellow;
  border-radius: 50%;
  height: thc-core.$space * 1.5;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%) scale(calc(1 / var(--car-point-scale, 1)));
  width: thc-core.$space * 1.5;
  z-index: 20;
}

.actions.actions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: thc-core.$space3;
  position: relative;
  width: 100%;
  z-index: 10;

  --thc-button-secondary-border-color: #{thc-color.$dark-grey-3};

  button {
    --button-color: #{thc-color.$primary-white};
    --button-min-width: auto;
    --button-background-color: transparent;
  }
}

.hintBox {
  align-items: center;
  background-color: thc-color.$dark-grey-1;
  border-radius: thc-core.$border-radius;
  box-shadow: inset 0 0 0 thc-core.$border-width thc-color.$dark-grey-3;
  color: thc-color.$primary-white;
  display: flex;
  padding: thc-core.$space3 thc-core.$space4;

  @media (orientation: landscape) and (max-height: $iphone-se-height) {
    padding: thc-core.$space * 1.5 thc-core.$space3;
  }

  @media (orientation: landscape) and (max-width: $iphone-se-width) {
    font-size: thc-core.$core-font-size - 2px;
    line-height: thc-core.$core-font-size + 4px;

    .hintNum {
      line-height: thc-core.$core-font-size * 1.5;
    }
  }

  .hintNum {
    background-color: thc-color.$primary-white;
    border-radius: 50%;
    color: thc-color.$dark-grey-1;
    display: inline-block;
    flex-shrink: 0;
    font-weight: 700;
    height: thc-core.$space4;
    margin-right: thc-core.$space;
    text-align: center;
    width: thc-core.$space4;
  }
}

.imgSwitch.imgSwitch {
  display: flex;
  gap: thc-core.$space;
  justify-content: center;
  margin-top: auto;

  &.hidden {
    pointer-events: none;
    visibility: hidden;
  }

  button {
    --thc-chip-default-bg-color: transparent;
    --thc-font-color: #{thc-color.$primary-white};
    --thc-chip-selected-bg-color: #{thc-color.$primary-white};
    --thc-chip-selected-border-color: #{thc-color.$primary-white};

    @media (orientation: landscape) and (max-width: $iphone-se-width) {

      > span:last-child {
        display: none;
      }
    }
  }
}

.infoBox {
  background:
    linear-gradient(
      360deg,
      thc-color.$primary-black 0%,
      sass-color.change(thc-color.$primary-black, $alpha: 0.3) 100%,
      sass-color.change(thc-color.$primary-black, $alpha: 0.1) 100%
    );
  bottom: 0;
  color: thc-color.$primary-white;
  left: 0;
  padding: thc-core.$space4 0;
  position: absolute;
  text-align: center;
  width: 100%;

  @include thc-typography.heading5;

  &::first-letter {
    text-transform: uppercase;
  }
}

.continue {
  position: absolute;
  right: thc-core.$space6;
  top: thc-core.$space;
}
