@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers.scss' as *;

$custom-size: thc-core.$space3;

.component {

  h1 {

    @include title3;

    margin-bottom: thc-core.$space4;
  }

  ol {
    counter-reset: list-counter;
    list-style: none;
    padding-left: thc-core.$space6;
    text-align: left;

    li {
      counter-increment: list-counter;
      margin: 0 0 thc-core.$space3 0;
      padding-left: thc-core.$space3;
      position: relative;

      &::before {

        @include caption;

        $size: thc-core.$space4;

        background-color: thc-color.$primary-black;
        border-radius: 50%;
        color: $white;
        content: counter(list-counter);
        height: $size;
        left: -$custom-size;
        line-height: $size;
        margin-bottom: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: $size;
      }
    }
  }

  @media (orientation: landscape) {
    text-align: left;

    // Camera wrapper
    & > div {
      max-width: none;
    }

    // Camera container
    > div > div {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
    }
  }

  .copy {
    margin-bottom: thc-core.$space3;
    padding: 0 thc-core.$space2;

    @media (orientation: landscape) {
      padding: 0;
    }
  }

  .camera > svg {
    margin-top: thc-core.$space3;
    max-height: calc(40vh - thc-core.$space9);

    @media (orientation: landscape) {
      margin-left: thc-core.$space5;
      margin-top: 0;
      max-height: calc(100vh - thc-core.$space9);
    }
  }
}

.fullHeight {

  @include centerContent;
}
