@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
$height-for-mobile: $marginGeneric * 71;
$font-size: thc-typography.$font-size - 4;

.component {

  h2 {
    margin-bottom: $marginGeneric * 4;

    @include maxTabletMobile {
      margin-bottom: $marginGeneric * 3;
    }
  }

  p.strap:last-of-type {
    margin-bottom: $marginGeneric * 6;
  }

  .copy {
    cursor: pointer;
    margin: 0 auto;
    max-width: $marginGeneric * 50;
    text-align: center;
    width: 100%;

    * {
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);
      -webkit-user-select: none;
      user-select: none;
    }

    .inputToken {

      input {
        padding-right: $marginGeneric * 8;
      }

      label {
        align-items: center;
        color: $primary_blue;
        display: flex;
        height: $marginGeneric * 6;
        padding: 0 $marginGeneric * 2;
        position: absolute;
        right: 0;
        top: $marginGeneric;
        z-index: 2;
      }
    }

    .inputPlaceholder {
      position: relative;
    }

    .tooltip {
      background-color: var(--thc-color-primary-black);
      border-radius: 4px;
      color: $white;
      left: 50%;
      opacity: 0.9;
      padding: $marginGeneric 0;
      position: absolute;
      top: -50px;
      transform: translateX(-50%);
      width: $marginGeneric * 11;

      &::before {
        background-color: var(--thc-color-primary-black);
        bottom: thc-core.$space1 * -1;
        -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        content: '';
        display: block;
        height: $marginGeneric;
        left: calc(50% - $marginGeneric);
        opacity: 0.9;
        position: absolute;
        width: $marginGeneric * 2;
      }
    }
  }

  .errorCode {

    @include small;
  }

  &.private,
  &.cookies {

    > div {
      flex-direction: column;
      min-height: 100vh;
    }

    h1 {

      @include title3;

      margin-bottom: $marginGeneric * 4;
      text-align: center;
    }

    .illustration {
      margin-bottom: $marginGeneric * 6;

      @media (max-height: $height-for-mobile) {
        display: none;
      }
    }

    .inputToken {
      margin-bottom: 0;
    }

    .steps {
      counter-reset: count;
      margin-bottom: $marginGeneric * 5;
      padding: 0 $marginGeneric * 2;
      width: 100%;

      @media (max-height: $height-for-mobile) {
        margin-bottom: $marginGeneric * 4;
      }

      li {
        margin-bottom: $marginGeneric * 2;
        padding-left: $marginGeneric * 4;
        position: relative;

        img {
          margin-left: calc($marginGeneric / 2);
          vertical-align: middle;
        }

        &::before {
          background-color: $primary_blue;
          border-radius: 50%;
          color: $white;
          content: counter(count);
          counter-increment: count;
          display: inline-block;
          font-size: $font-size;
          font-weight: 700;
          height: $marginGeneric * 2.625;
          left: 0;
          line-height: $marginGeneric * 2.625;
          margin-right: $marginGeneric + 2;
          position: absolute;
          text-align: center;
          top: calc($marginGeneric / 4);
          width: $marginGeneric * 2.625;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &.cookies {

    h1 {
      margin-bottom: $marginGeneric * 6;
    }

    .illustration {
      margin-bottom: $marginGeneric * 8;
    }

    .steps {
      margin: 0 auto $marginGeneric * 5;
      width: auto;

      @media (max-height: $height-for-mobile) {
        margin: 0 auto $marginGeneric * 4;
      }
    }
  }
}
