@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.skipButton {

  @include large;

  background-color: transparent;
  border: 0;
  color: $white;
  cursor: pointer;
  left: 50%;
  margin-bottom: 0;
  outline: none;
  padding: 0;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  top: $marginGeneric * 4;
  transform: translateX(-50%);
}
