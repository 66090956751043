@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers' as *;

.dialog {
  margin: 0 !important;
  max-width: unset !important;

  & > div {
    border-radius: 0 !important;
    margin: 0 !important;
    max-width: unset !important;
    padding: 0 !important;

    @include minMobile {
      overflow: visible !important;
    }
  }
}

$height: calc(100vh - thc-core.$space * 18); // screen height - footer buttons
// to prevent scrolling when there is only one photo eg damage page

.component {
  min-height: $height;

  .content {
    display: flex;
    flex-direction: column;
    min-height: $height;
    padding:
      thc-core.$space7 calc(thc-core.$space4 + env(safe-area-inset-left))
      thc-core.$space5 calc(thc-core.$space4 + env(safe-area-inset-right));

    & > p {
      padding-bottom: thc-core.$space4;
      text-align: center;

      @include minMobile {
        padding-bottom: thc-core.$space7;
      }
    }

    &.explanation {
      padding-top: thc-core.$space5;
      text-align: left;

      @media screen and (min-width: thc-breakpoints.$breakpointTabletMobile), (orientation: landscape) {
        min-height: calc(100vh - thc-core.$space * 10);
      }

      &.isDone {
        min-height: calc(100vh - thc-core.$space * 10);
      }

      @include minMobile {
        margin: 0 auto;

        &:not(.hasImages) {
          max-width: thc-core.$space * 70;
        }
      }

      p.description {
        margin-left: 0;
        margin-top: 0;
        padding-bottom: thc-core.$space5;
        text-align: left;

        @include thc-typography.large;
      }

      p.subParagraph {
        font-weight: 700;
        margin-top: 0;
        padding-bottom: thc-core.$space3;
        text-align: left;

        @include thc-typography.large;
      }

      .columns {
        gap: thc-core.$space5;

        @include minMobile {
          gap: thc-core.$space3;

          &.one {
            grid-template-columns: 1fr;

            .imageWrapper {
              padding-bottom: 55.5%;
            }
          }
        }

        @media (orientation: landscape) {

          &.one {

            .imageWrapper {
              padding-bottom: 0;
            }
          }
        }

        .imageWrapper {
          margin-bottom: 0;
          margin-left: auto;
          margin-right: auto;
          max-width: thc-core.$space1 * 95;
        }

        h3 {
          margin-bottom: thc-core.$space2;
        }

        p {
          margin-bottom: auto;
          padding-bottom: thc-core.$space3;

          @include thc-typography.regular;
        }
      }
    }
  }

  .header {

    @include thc-typography.heading3;

    margin-bottom: thc-core.$space5 !important;
  }

  .description {
    margin: 0 auto;
    max-width: thc-core.$space * 74;
  }

  .iconTick {
    --thc-icon-fill: var(--thc-semantic-success);
  }

  .iconBad {
    --thc-icon-fill: var(--thc-semantic-error);
  }

  .subParagraph {
    margin-top: -#{thc-core.$space};

    @include minMobile {
      margin-top: -#{thc-core.$space4};
    }
  }

  .stickyButton {
    background:
      linear-gradient(
        180deg,
        rgba(thc-color.$primary-white, 0) 0%,
        rgba(thc-color.$primary-white, 1) 100%
      ) no-repeat;
    bottom: 0;
    position: sticky;
    z-index: 99;

    .footer {
      padding: thc-core.$space7 thc-core.$space4;
    }
  }
}

.contentBox {
  margin-bottom: thc-core.$space7;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  gap: thc-core.$space3;
  justify-content: center;
  margin-bottom: thc-core.$space5;

  @include minMobile {

    .column {
      flex: 1 1 calc(50% - thc-core.$space4 / 2);

      &:nth-child(2n) {
        margin-right: 0;
      }

      .imageWrapper + p {
        padding-bottom: 0;
      }
    }
  }

  .column {
    display: flex;
    flex: 1 1 calc(50% - thc-core.$space4);
    flex-direction: column;
    max-width: thc-core.$space2 * 47.5;
    min-width: thc-core.$space2 * 25;

    &:first-child h3 {
      margin-top: thc-core.$space2;

      @include minMobile {
        margin-top: 0;
      }
    }

    .imageWrapper {
      border-radius: 4px;
      margin-bottom: thc-core.$space4;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      + p {
        margin-top: thc-core.$space2 * -1;
        padding-bottom: thc-core.$space4;
      }
    }

    h3 {

      @include thc-typography.heading5;

      margin-bottom: thc-core.$space3;
      text-align: left;
    }

    h5 {

      @include thc-typography.heading5;

      display: flex;
      height: thc-core.$space4;
      margin-bottom: thc-core.$space3;

      span:last-of-type {
        margin-left: thc-core.$space;
      }
    }

    ul {
      margin-bottom: 0;
    }

    ul > li {
      align-items: flex-start;
      display: flex;
      margin-bottom: thc-core.$space3;

      svg {
        padding: thc-core.$space + 2;

        path {
          fill: thc-color.$primary-black;
        }
      }

      span {
        margin-left: thc-core.$space;
      }
    }
  }
}

.contactBlock {
  border-radius: thc-core.$space-16;
  border: 1px solid var(--thc-component-border);
  padding: thc-core.$space7 thc-core.$space5 thc-core.$space5;
  position: relative;

  .agentsBox {
    position: absolute;
    top: 0;
    transform: translateY(-50%);

    span {
      border-width: thc-core.$space1 - 1;
    }
  }

  .contactLinks {
    display: flex;
    flex-direction: column;
    gap: thc-core.$space3;
    margin-bottom: thc-core.$space4;
  }

  .info {

    @include thc-typography.heading5;

    margin-bottom: thc-core.$space4;
    padding-bottom: 0;
  }

  .openHours {

    @include thc-typography.small;

    padding: 0;
  }
}
