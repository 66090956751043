@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use 'sass:map';

@use '~@THC/styles/theme/theme-vars.scss' as themeVars;

@use '../../../assets/scss/helpers' as *;

$sidebar-width: $marginGeneric * 20;

@mixin tooltip-base {
  background-clip: padding-box;
  background-color: rgba(thc-color.$primary-black, 90%);
  border: solid 1px;
  border-radius: 4px;
  opacity: 0;
  outline: none;
  padding: $marginGeneric * 2;
  pointer-events: none;
  position: absolute;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  transition: opacity 0.2s linear;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .side {
    display: flex;
    position: absolute;

    &::before {
      content: '';
      flex: 1;
    }

    &::after {
      content: '';
      flex: 1;

    }
  }

  .arrow {
    height: 14px;
    overflow: hidden;
    width: 20px;

    &::before {
      background-color: rgba(thc-color.$primary-black, 90%);
      border: solid 1px rgba(thc-color.$primary-white, 25%);
      content: '';
      display: block;
      height: 20px;
      transform: rotate(45deg);
      width: 20px;
    }
  }
}

.dimmer {
  background-color: var(--thc-color-primary-black);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 95;
}

.content {
  background-color: var(--thc-color-primary-black);
  color: thc-color.$primary-white;
  left: 0;
  min-height: 100vh;
  overflow: hidden;
  padding: 8vh $marginGeneric * 8 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 96;

  h3 {

    @include title3;

    margin-bottom: $marginGeneric * 3;
    text-align: center;
  }

  p {
    text-align: center;
  }
}

.damageEditor {
  display: flex;
  position: relative;

  .helpButton {
    bottom: $marginGeneric * 2.5;
    height: $marginGeneric * 6 !important;
    left: calc(var(--sidebar-width) / 4);
    position: absolute;
    transform: translateX(-110%);
    width: $marginGeneric * 6 !important;
    z-index: 10;
  }

  .col {
    width: calc(100% - $sidebar-width);
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: $sidebar-width;

    [data-thc-button] {

      @include themeVars.generateVars('dark', map.get(themeVars.$theme, 'thc', 'button'), '-thc-button');

      --thc-accent-font-color: var(--thc-accent-font-color-inverse);
    }

    .cancelButton {

      @include linkButton;

      display: block;
      height: $marginGeneric * 3;
      margin: 0 auto $marginGeneric * 5;
      width: $marginGeneric * 12;
    }

    .sidebarContent {
      margin-bottom: $marginGeneric * 7;
      position: relative;
      transition: opacity 0.3s linear;

      &.inactive {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    .submitButton {
      flex: 1;
      margin: 0 $marginGeneric * 3 0;

      > [disabled][data-thc-button] {
        --button-opacity: 0.2;
      }
    }
  }

  h2 {

    @include title5;

    margin-bottom: $marginGeneric * 3;
    text-align: center;
    user-select: none;
  }
}

.imageContainer {
  align-items: center;
  display: flex;
  height: 50vh;
  justify-content: center;
  margin-bottom: 5vh;
  position: relative;

  .imageWrapper {
    position: relative;
    touch-action: none;
    transition: opacity 0.1s linear;

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  img {
    max-height: 44vh;
    max-width: 100%;
    pointer-events: none;
    transition: opacity 0.1s linear;

    &.fadeImage {
      opacity: 0;
    }
  }

  .point {
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;

    &::before {
      background-color: yellow;
      border-radius: 50%;
      content: '';
      display: block;
      transform: translate(-50%, -50%);
      transition: height 0.2s linear, width 0.2s linear;
    }

    &.small {

      &::before {
        height: $marginGeneric * 2;
        width: $marginGeneric * 2;
      }
    }

    &.medium {

      &::before {
        height: $marginGeneric * 4;
        width: $marginGeneric * 4;
      }
    }

    &.large {

      &::before {
        height: $marginGeneric * 6;
        width: $marginGeneric * 6;
      }
    }
  }
}

.sizeSlider {
  display: flex;
  flex-shrink: 0;
  padding-left: $marginGeneric * 8;
  position: relative;
  width: 200px;

  input[type='range'] {
    appearance: none;
    background: transparent;
    flex-shrink: 0;
    height: 0;
    left: $marginGeneric * 4;
    position: absolute;
    top: 0;
    touch-action: none;
    transform: rotate(90deg) translate(0, -100%);
    transform-origin: left;
    width: 100%;

    &::-webkit-slider-runnable-track {
      background: var(--thc-color-dark-grey);
      border-radius: 12px;
      cursor: default;
      height: 8px;
      width: 100%;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background: var(--thc-color-off-black);
      border: 3px solid #eceff1;
      border-radius: 50%;
      box-sizing: border-box;
      cursor: default;
      height: 22px;
      margin-top: -7px;
      width: 22px;
    }
  }

  .labels {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .label {
      font-weight: bold;
      line-height: $marginGeneric * 2;
      outline: none;
      -webkit-tap-highlight-color: transparent;

      span {

        @include caption;

        display: block;
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: -$marginGeneric;
      }

      &:last-child {
        margin-bottom: -$marginGeneric;
      }
    }
  }
}

.sidePicker {
  display: flex;
  justify-content: space-evenly;

  .sideItem {

    @include small;

    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;
    width: 20%;

    .sideButton {
      align-items: center;
      background-color: var(--thc-color-mid-grey);
      border: 0;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      margin-bottom: $marginGeneric * 2;
      transition: background-color 0.2s linear;
      width: 40px;

      &.active {
        background-color: $primary_blue;

        + label {
          font-weight: bold;
        }
      }

      :global(.motorway-svg-fill) {
        fill: thc-color.$primary-white;
      }
    }
  }

  &.hideSidePicker {
    display: none;
  }
}

.sideTooltip {

  @include tooltip-base;

  border-color:
    rgba(thc-color.$primary-white, 25%) rgba(thc-color.$primary-white, 25%)
    transparent rgba(thc-color.$primary-white, 25%);
  bottom: $marginGeneric * 8;
  left: 50%;
  min-width: $marginGeneric * 15;
  transform: translate(-50%, 100%);

  .side {
    align-items: flex-end;
    bottom: 1px;
    left: 0;
    transform: rotate(180deg) translateY(-100%);
    width: 100%;

    &::before {
      border-left: 1px solid rgba(thc-color.$primary-white, 25%);
      border-radius: $borderRadiusGeneric 0 0;
      border-top: 1px solid rgba(thc-color.$primary-white, 25%);
      height: 2px;
    }

    &::after {
      border-radius: 0 $borderRadiusGeneric 0 0;
      border-right: 1px solid rgba(thc-color.$primary-white, 25%);
      border-top: 1px solid rgba(thc-color.$primary-white, 25%);
      height: 2px;
    }
  }

  .arrow {
    margin-bottom: 1px;

    &::before {
      margin-top: 7px;
    }
  }
}

.locationTooltip {

  @include tooltip-base;

  border-color:
    transparent rgba(thc-color.$primary-white, 25%)
    rgba(thc-color.$primary-white, 25%) rgba(thc-color.$primary-white, 25%);
  bottom: 0;
  left: 50%;
  min-width: $marginGeneric * 37;
  transform: translate(-50%, 100%);

  .side {
    align-items: flex-end;
    bottom: calc(100% - 1px);
    left: 0;
    width: 100%;

    &::before {
      border-left: 1px solid rgba(thc-color.$primary-white, 25%);
      border-radius: $borderRadiusGeneric 0 0;
      border-top: 1px solid rgba(thc-color.$primary-white, 25%);
      height: 2px;
    }

    &::after {
      border-radius: 0 $borderRadiusGeneric 0 0;
      border-right: 1px solid rgba(thc-color.$primary-white, 25%);
      border-top: 1px solid rgba(thc-color.$primary-white, 25%);
      height: 2px;
    }
  }

  .arrow {
    margin-bottom: 1px;

    &::before {
      margin-top: 7px;
    }
  }
}

.sizeTooltip {

  @include tooltip-base;

  border-color:
    rgba(thc-color.$primary-white, 25%) transparent
    rgba(thc-color.$primary-white, 25%) rgba(thc-color.$primary-white, 25%);
  top: 50%;
  transform: translate(calc(-100% - $marginGeneric * 7), -50%);
  white-space: nowrap;

  .side {
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    left: calc(100% - 1px);
    top: 0;

    &::before {
      border-radius: 0 $borderRadiusGeneric 0 0;
      border-right: 1px solid rgba(thc-color.$primary-white, 25%);
      border-top: 1px solid rgba(thc-color.$primary-white, 25%);
      width: 2px;
    }

    &::after {
      border-bottom: 1px solid rgba(thc-color.$primary-white, 25%);
      border-radius: 0  0 $borderRadiusGeneric 0;
      border-right: 1px solid rgba(thc-color.$primary-white, 25%);
      width: 2px;
    }
  }

  .arrow {
    height: 20px;
    width: 14px;

    &::before {
      margin-left: -12px;
    }
  }
}

.errorBlock {
  position: absolute;
  z-index: 100;
}
