@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  17.4%,
  82.6% { // 800ms/4600ms = 17.4% & 3800ms/4600ms = 82.6%
    opacity: 1;
  }
}

.overlay {
  align-items: center;
  animation: fadeInOut 4.6s forwards;
  background: rgba(0, 0, 0, 75%);
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.message {

  @include thc-typography.heading6;

  color: var(--primary-white, #fff);
  text-align: center;
}
