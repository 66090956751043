@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  background-color: thc-color.$primary-white;
  min-height: calc(100vh - (thc-core.$space2 * 18)); // 100vh - footer height (144px) = min-height
  padding: thc-core.$space5 thc-core.$space4;
}

.container {
  margin: 0 auto;
  max-width: thc-core.$space2 * 64;

  .header {
    display: flex;
    flex-direction: column;
    gap: thc-core.$space5;
    margin-bottom: thc-core.$space5;

    h1 {

      @include thc-typography.heading3;

    }

    p {

      @include thc-typography.regular;

      padding: 0;
    }
  }

  .fieldSet {
    display: flex;
    flex-direction: column;
    gap: thc-core.$space3;
    margin-bottom: thc-core.$space8;
  }
}