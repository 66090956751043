@use '~@THC/styles/core' as thc-core;

@use '~@THC/styles/typography' as thc-typography;

@use '~@THC/styles/color' as thc-color;

$breakpoint-iPhone8: 667px;
$breakpoint-iPhoneX: 812px;
$breakpoint-iPad: 1024px;
$fontSize: thc-typography.$font-size - 2;

@mixin centerContent {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - (thc-core.$space * 8)); // Accommodate header
}

@mixin containerPadding {
  padding-left: thc-core.$space * 3;
  padding-right: thc-core.$space * 3;

  @media screen and (min-width: $breakpoint-iPhone8) {
    padding-left: thc-core.$space * 4;
    padding-right: thc-core.$space * 4;
  }

  @media screen and (min-width: $breakpoint-iPhoneX) {
    padding-left: thc-core.$space * 7;
    padding-right: thc-core.$space * 7;
  }

  @media screen and (min-width: $breakpoint-iPad) {
    padding-left: thc-core.$space * 15;
    padding-right: thc-core.$space * 15;
  }
}

@mixin fromPad {

  @media screen and (min-width: $breakpoint-iPad) {
    display: block;
  }

  display: none;
}

@mixin untilPad {

  @media screen and (min-width: $breakpoint-iPad) {
    display: none;
  }

  display: block;
}

@mixin linkButton {
  background: none;
  border: none;
  color: thc-color.$primary-white;
  cursor: pointer;
  font-size: thc-typography.$font-size;
  outline: none;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
