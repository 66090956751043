@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  align-items: center;
  background-color: thc-color.$primary-white;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: calc(100vh - thc-core.$component-height-large);
  padding: 0 thc-core.$space4 thc-core.$space4;

  .heading {
    display: flex;
    justify-content: center;
    padding: thc-core.$space7 0;

    h1 {

      @include thc-typography.heading3;

    }
  }

  .content {

    .list {
      border-bottom: 1px solid thc-color.$light-grey-3;
      display: flex;
      gap: thc-core.$space3;
      padding-bottom: thc-core.$space3;

      .icon {
        align-items: center;
        display: flex;
        justify-content: center;

        --thc-icon-fill: #{thc-color.$primary-black};
      }

      .title {

        @include thc-typography.heading6;

      }

      .description {

        @include thc-typography.regular();

        color: thc-color.$dark-grey-3;
        padding: 0;
      }

      &:not(:first-child) {
        padding-top: thc-core.$space3;
      }
    }

    @media (orientation: landscape) {
      min-width: thc-core.$space9 * 8;
    }
  }
}