@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.app {
  min-width: 100vw;
  position: relative;
  transform: translateX(var(--app-translate-x, 0));
  transition: transform 0.2s linear;

  @include minTabletMobile {
    transform: translateX(var(--app-translate-tablet-x, 0));
  }

  :global(.capture-screen) & {
    transform: none;
  }
}
