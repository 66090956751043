@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers.scss' as *;

.component {
  position: relative;

  .buttonBox {
    bottom: $marginGeneric * 3;
    margin-bottom: $marginGeneric * 3;
    margin-top: auto;
    position: sticky;
    width: 100%;

    @media (orientation: landscape) {
      bottom: unset;
      position: unset;
    }
  }
}

.center {

  @include centerContent;

  background-color: $white;
}

.start {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  text-align: center;

  h1 {

    @include title2;
  }

  p {

    &:nth-of-type(2) {
      margin-bottom: $marginGeneric * 4;

      @include maxTabletMobile {
        padding-bottom: 0;
      }
    }
  }

  .vehicleDetails {
    align-items: center;
    background-color: $white;
    border-radius: $borderRadiusGeneric;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $marginGeneric * 3;
    padding: $marginGeneric * 3;
    text-align: left;
    width: 100%;

    .make {
      height: $marginGeneric * 6;
      margin-right: $marginGeneric * 2;
      width: $marginGeneric * 6;

      &::before {
        height: $marginGeneric * 6;
        width: $marginGeneric * 6;
      }

      @include maxTabletMobile {
        height: $marginGeneric * 4;
        width: $marginGeneric * 4;

        &::before {
          height: $marginGeneric * 4;
          width: $marginGeneric * 4;
        }
      }
    }

    .vrmBadge {
      align-items: center;
      display: flex;
      margin-bottom: $marginGeneric * 2;
      width: 100%;
    }

    h4 {

      @include title5;

      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      margin-bottom: $marginGeneric;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-break: break-all;
    }
  }

  .change {
    border-color: rgba($primary_blue, 0.3);
    color: $primary_blue;
    margin: 0 auto ($marginGeneric * 3);

    &:hover {
      border-color: $primary_blue;
    }
  }
}
