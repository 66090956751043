@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers' as *;

.component {
  background-color: thc-color.$primary-white;
  height: 100vh;
  width: 100%;

  &.withStatus404 {
    height: auto;
  }

  video {
    display: block;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
  }

  .videoBox {
    background-color: thc-color.$primary-black;
    position: fixed;
    top: 0;
    transform: translateY(-120%);

    &.show {
      position: static;
      transform: none;
    }
  }

  .errorBox {
    color: thc-color.$primary-white;
    left: 50%;
    padding: thc-core.$space4;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    h3 {

      @include thc-typography.heading6;
    }

    p {

      @include thc-typography.small;
    }
  }
}

.fullHeight {

  @include centerContent;
}
