@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  text-align: center;

  .img {
    margin: 0 auto;
    max-width: $marginGeneric * 34;
    padding: 0 $marginGeneric * 3 $marginGeneric * 6;
    pointer-events: none;
    width: 100%;

    svg {
      height: $marginGeneric * 36;
      width: auto;
    }
  }

  .content {
    width: 100%;

    h2 {

      @include title2;

      margin-bottom: $marginGeneric * 4;

      @include minTabletMobile {

        @include title3;

        margin-bottom: $marginGeneric * 3;
      }
    }

    p:last-of-type {
      margin-bottom: $marginGeneric * 4;
      padding-bottom: 0;
    }
  }
}
