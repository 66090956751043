@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.stickyBar {
  background-color: thc-color.$primary-white;
  bottom: 0;
  box-shadow: 0 0 thc-core.$space3 rgba(thc-color.$primary-black, 10%);
  left: 0;
  position: sticky;
  right: 0;
  z-index: 20;

  &.noShadowBox {
    background-color: unset;
    box-shadow: none;
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: thc-core.$space3;
    justify-content: space-between;
    padding: thc-core.$space3 thc-core.$space4;
    white-space: nowrap;

    @include minMobile {
      flex-direction: row;
      max-width: thc-core.$space * 64;
      padding: thc-core.$space3 0;

      &.reverse {
        flex-direction: row-reverse;
      }
    }

    @media (orientation: landscape) {
      flex-direction: row-reverse;
    }
  }
}
