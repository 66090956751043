@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@import '~@motorway/motorway-storybook-cra/dist/base.css';

@import '~@motorway/motorway-storybook-cra/dist/themeLight.css';

@import '~@motorway/motorway-storybook-cra/dist/main.css';

@viewport {
  user-zoom: fixed;
  width: 100vw;
  zoom: 1;
}

// If we add overflow: hidden only on body we can scroll, thank you Safari =(
html,
body {
  overflow: var(--body-overflow, visible);
}

body {
  background-color: var(--body-bg-color, var(--thc-color-primary-yellow));
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  touch-action: pan-x pan-y;
  zoom: 1;

  &.capture-screen {
    background-color: #000;
  }

  &.zoomed {
    touch-action: auto;
  }
}

button {
  font-family: inherit;
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
