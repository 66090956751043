@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers' as *;

$animation-cycle: 6s;

.component {
  align-items: center;
  background-color: thc-color.$primary-yellow;
  color: thc-color.$primary-black;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  max-height: 100vh; // Strange bug in Chrome beta v.78 where it's inheriting `--overlay-bg-size-width`
  max-width: 100vw; // for some reason and being too tall and wide
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;

  .imageBlock {
    align-items: center;
    display: flex;
    height: 256px;
    justify-content: center;
    margin-bottom: thc-core.$space5;
    position: relative;
  }

  .textBlock {
    padding: 0 thc-core.$space3;
    text-align: center;

    h1 {

      @include title3;
    }
  }

  .phoneFrame {
    animation: frameAntiClockwise $animation-cycle linear infinite;
    background-image: url('../../../assets/images/loaders/phone.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 124px;
    padding: 4px;
    transform: rotate(90deg);
    width: 256px;
  }

  .phoneMask {
    align-items: center;
    clip-path: inset(0 0 0 0 round 4px);
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  .carImage {
    animation: carClockwise $animation-cycle linear infinite;
    background-image: url('../../../assets/images/loaders/white-car-side.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 64px;
    transform: rotate(-90deg);
    width: 144px;
  }

  .greenTick {
    animation: greenTick $animation-cycle infinite;
    animation-timing-function: linear;
    opacity: 0;
    position: absolute;
  }

  // animation for phone frame

  @keyframes frameAntiClockwise {
    // start from portrait
    0% {
      transform: rotate(90deg);
    }

    // rotate to landscape (1.5s), delay (2.5s)
    25%,
    66% {
      transform: rotate(0deg);
    }

    // rotate back to portrait
    83%,
    100% {
      transform: rotate(90deg);
    }
  }

  // animation for car not to rotate inside a rotating phone frame

  @keyframes carClockwise {

    0% {
      transform: rotate(-90deg);
    }

    25%,
    66% {
      transform: rotate(0deg);
    }

    83%,
    100% {
      transform: rotate(-90deg);
    }
  }

  @keyframes greenTick {

    0%,
    25% {
      opacity: 0;
    }

    30%,
    66% {
      opacity: 1;
    }

    70%,
    100% {
      opacity: 0;
    }
  }
}
