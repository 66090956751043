@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use 'sass:color' as sasscolor;

@use '~@THC/styles/theme/theme-vars.scss' as themeVars;

.overlay {
  background-color: sasscolor.change(thc-color.$primary-black, $alpha: 0.5);
  inset: 0;
  opacity: 0; // Start with an invisible overlay
  position: fixed;
  transition: visibility 0s linear 0.3s, opacity 0.3s linear; // Delay the visibility transition
  visibility: hidden; // Initially hidden
  z-index: 110;

  &.isOpen {
    opacity: 1; // Fully visible when open
    transition: visibility 0s linear 0s, opacity 0.3s linear; // No delay when becoming visible
    visibility: visible; // Make visible when open
  }
}

.imageAssistModal {
  background-color: thc-color.$primary-white;
  border-bottom-left-radius: thc-core.$border-radius;
  border-right: none;
  border-top-left-radius: thc-core.$border-radius;
  bottom: 0;
  color: thc-color.$primary-black;
  contain: content;
  display: flex;
  flex-direction: column;
  position: fixed;
  transform: translateY(100%);
  transition: transform 0.3s ease-out, visibility 0.3s ease-out;
  visibility: hidden;
  width: 100%;
  z-index: 120;

  @media (orientation: landscape) {
    height: 100vh;
    max-width: thc-core.$space * 47.5;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    overscroll-behavior: contain;
    pointer-events: none;
    right: 0;
    transform: translateX(100%);
  }

  &.isOpen {
    pointer-events: all;
    transform: translateY(0);
    visibility: visible;

    @media (orientation: landscape) {
      transform: translateX(0);
    }
  }

  .close {
    background-color: thc-color.$primary-white;
    border-radius: thc-core.$border-radius;
    display: inline-flex;
    justify-content: flex-end;
    padding: thc-core.$space2 + 4;
    position: sticky;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    --thc-icon-fill: #{thc-color.$light-grey-1};
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: thc-core.$space5;
    padding: 0 thc-core.$space4 thc-core.$space4;

    @media (orientation: portrait) and (min-width: thc-breakpoints.$breakpointTabletMobile + 1) {
      margin: 0 auto;
      max-width: thc-core.$space * 53.5;
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: thc-core.$space3;

      .heading {
        --thc-icon-fill: #{thc-color.$secondary_red};
      }

      h2 {

        @include thc-typography.heading3;
      }

      p {

        @include thc-typography.regular;

        color: thc-color.$dark-grey-3;
        padding: 0;
      }
    }

    .images {
      display: grid;
      gap: thc-core.$space1;
      grid-template-columns: 1fr 1fr;

      .image {
        margin: 0;
        position: relative;

        img {
          border-radius: thc-core.$space1;
        }

        .icon {
          background: white;
          border-radius: 50%;
          bottom: -5%;
          left: 50%;
          max-height: thc-core.$space5;
          max-width: thc-core.$space5;
          position: absolute;
          transform: translateX(-50%);
        }

        .tickIcon {
          --thc-icon-fill: #{thc-color.$secondary_green};
        }

        .crossIcon {
          --thc-icon-fill: #{thc-color.$secondary_red};
        }
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: thc-core.$space4;
      margin-top: auto;
    }
  }
}
