@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  background-color: var(--thc-color-primary-yellow);
  color: var(--thc-color-primary-black);

  .content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: $marginGeneric * 72; // 528px with padding
    min-height: calc(100vh - ($marginGeneric * 8));
    padding: $marginGeneric * 3;
    width: 100%;
  }
}
