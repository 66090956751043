@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.subtitleIcon {
  display: flex;
}

.isApproved {
  --thc-icon-fill: var(--thc-semantic-success);

  svg {
    color: thc-color.$transparent;
  }
}

.isSkipped {
  --thc-icon-fill: #{thc-color.$primary-black};

  svg {
    color: thc-color.$transparent;
  }
}

.isRetake {
  --thc-icon-fill: var(--thc-semantic-error);

  svg {
    color: thc-color.$transparent;
  }
}

.isFailed {
  --thc-icon-fill: var(--thc-semantic-error);
  --thc-icon-accent-fill: #{thc-color.$primary-black};
  --thc-input-file-description-color: #{thc-color.$primary-white};
}

.isBrokenImage {
  --thc-icon-fill: var(--thc-semantic-error);
  --thc-icon-accent-fill: #{thc-color.$primary-black};

  svg {
    color: thc-color.$transparent;
  }
}

.photo {
  display: grid;
  grid-gap: $marginGeneric * 2;
  width: 100%;

  @media screen and (min-width: thc-breakpoints.$breakpointTabletMobile), (orientation: landscape) {
    grid-template-columns: 1fr 1fr;
  }
}

.link.link {

  @include mw-link-white;
}

.errorInfo.errorInfo {
  padding-bottom: thc-core.$space;
}

.faultDetected {
  --thc-icon-fill: var(--thc-semantic-warning);
}

.imageAssessmentPending {
  --thc-icon-fill: #{thc-color.$primary-white};
}

.aiFeedbackBox {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  pointer-events: all !important;
}

.aiFeedback {
  align-items: center;
  display: flex;
  gap: thc-core.$space;
}

.aiLink {

  @include thc-typography.small;

  color: thc-color.$primary-white;
  font-weight: normal;
  opacity: 0.8;
  padding-left: thc-core.$space3;
}
