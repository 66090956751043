@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  background-color: thc-color.$primary-white;
  padding: thc-core.$space5 thc-core.$space4;

  .heading {
    display: flex;
    flex-direction: column;

    h1 {
      @include thc-typography.heading3;
      padding-bottom: thc-core.$space5;
    }

    p {
      @include thc-typography.regular;
      padding-bottom: thc-core.$space5;
    }
  }

  .messagingCardSection {
    margin: 0 auto thc-core.$space4;
    max-width: thc-core.$space * 64;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-bottom: thc-core.$space3;
    width: 100%;

    @media screen and (min-width: thc-breakpoints.$breakpointMobile) {
      margin: 0 auto thc-core.$space3;
      max-width: thc-core.$space * 64;
    }
  }
}
