@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;

  [data-thc-logo] {
    position: relative;
    z-index: 1;
  }

  .chevron {
    animation-delay: 1s;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-name: chevron;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transform: translate3d(-100%, 0, 0);
    width: 100%;

    @keyframes chevron {

      0% {
        transform: translate3d(-100%, 0, 0);
      }

      66% {
        transform: translate3d(100%, 0, 0);
      }

      100% {
        transform: translate3d(100%, 0, 0);
      }
    }

    &::before,
    &::after {
      background-color: $white;
      content: '';
      display: block;
      height: 200%;
      min-height: 1000px;
      position: absolute;
      right: 0;
      width: $marginGeneric * 10;
    }

    &::before {
      bottom: 50%;
      transform: rotate(-30deg);
      transform-origin: bottom right;
    }

    &::after {
      top: 50%;
      transform: rotate(30deg);
      transform-origin: top right;
    }
  }
}
