@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  background-color: thc-color.$primary-yellow;
  color: thc-color.$primary-black;
  display: flex;
  height: calc(100% + thc-core.$space5);
  justify-content: center;
  left: 0;

  // Strange bug in Chrome beta v.7component8 where it's inheriting `--overlay-bg-size-width`
  max-height: calc(100vh + thc-core.$space5);
  max-width: 100vw; // for some reason and being too tall and wide
  position: absolute;
  text-align: center;
  top: 0;
  transform: translate3d(0, var(--scroll-down-hint-transform), 0);
  transition: transform 0.1s linear;
  width: 100%;
  z-index: 99; // Not to obscure the rotate screen

  &::before {
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }

  .loader {
    transform: rotate(90deg);
  }

  p {

    @include title3;

    margin-bottom: 0;
    padding: thc-core.$space6 0 thc-core.$space9;
  }
}
