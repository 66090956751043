@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {

  @include small;

  background-color: var(--thc-color-primary-black);
  border: solid 1px var(--thc-color-mid-grey);
  border-radius: $borderRadiusGeneric;
  color: $white;
  left: 50%;
  opacity: 0;
  padding: $marginGeneric * 2;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: $marginGeneric * 3;
  transform: translateX(-50%);
  transition: opacity 0.2s linear;
  width: calc(100% - var(--sidebar-width) - var(--sidebar-width) - $marginGeneric * 6);
  z-index: 20;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
