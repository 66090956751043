@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  display: flex;
  font-weight: thc-typography.$font-weight-bold;

  --thc-icon-fill: #{thc-color.$primary-black};

  svg {
    margin-right: thc-core.$space;
  }
}
