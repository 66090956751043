@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component,
.wrapper {
  background-color: thc-color.$primary-yellow;
  position: relative;
  z-index: 2;

  &.scroll {

    @include thc-typography.heading5;

    background-color: #000;
    color: thc-color.$primary-white;
    height: thc-core.$space * 11; // Accommodate iOS15 new tab bar
    margin-bottom: 0;

    svg {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: slidedown;
      margin-left: thc-core.$space;
      position: absolute;
      transform: rotate(90deg);

      :global(.motorway-svg-fill) {
        fill: thc-color.$primary-white;
      }

      @keyframes slidedown {

        0% {
          opacity: 1;
          transform: rotate(90deg) translate3d(thc-core.$space * -1, 0, 0);
        }

        25% {
          opacity: 1;
        }

        50%,
        100% {
          opacity: 0;
          transform: rotate(90deg) translate3d(thc-core.$space, 0, 0);
        }
      }
    }
  }

  &.scrollDownHint {
    z-index: 60;
  }
}

[data-thc-logo] svg {
  height: thc-core.$space6;
  width: thc-core.$space * 17;
}

.wrapper {
  display: var(--header-display, block);
}

.component {
  align-items: center;
  display: flex;
  height: thc-core.$space9;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 0;
  position: relative;

  a {
    border-bottom: 0;
  }
}
