@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
$customHeight: $marginGeneric * 53;

section {

  &.screen {
    display: block;
    padding-top: 50vh;
    pointer-events: none;
    position: relative;
    text-align: center;

    @media (orientation: landscape) and (max-height: $customHeight) {
      // Give landscape breathing room
      padding-top: $marginGeneric * 22; // Mobile
    }

    h2 {

      @include title3;

      margin-bottom: 0;
      position: relative;
      z-index: 2;
    }

    &.screen2 {
      overflow: hidden;
    }

    &.screen3 {

      .sun {
        position: absolute;
        right: $marginGeneric * 2;
        top: 10%;
      }
    }

    &.screen5 {
      display: flex;
      justify-content: center;
      overflow: hidden;

      .content {
        bottom: 0;
        height: 50%;
        max-width: $marginGeneric * 60;
        overflow: hidden;
        position: absolute;
        transform: scale(1.5);
        transform-origin: center bottom;
        width: 100%;

        @media (orientation: landscape) and (max-height: $customHeight) {
          bottom: 14%;
        }

        &::before,
        &::after {
          background-image: url('../../../../assets/images/loaders/hand.svg');
          background-position: right 50%;
          background-repeat: no-repeat;
          bottom: 0;
          content: '';
          display: block;
          height: 100%;
          position: absolute;
          width: 100%;
        }

        &::before {
          right: 75%;
        }

        &::after {
          left: 75%;
          transform: scaleX(-1);
        }

        .phone {
          background-image: url('../../../../assets/images/loaders/phone.svg');
          background-position: 50% 45%;
          background-repeat: no-repeat;
          background-size: contain;
          bottom: 0;
          height: 100%;
          left: 50%;
          max-width: $marginGeneric * 38;
          position: absolute;
          transform: translateX(-50%);
          width: 80%;
          z-index: 1;
        }
      }

      &.animate .content {
        animation-delay: 0.3s;
        animation-duration: 0.8s;
        animation-fill-mode: forwards;
        animation-name: hands;

        @keyframes hands {

          from {
            transform: scale(1.5) translate3d(0, 0, 0);
          }

          to {
            transform: scale(1) translate3d(0, calc(-50% - 60px), 0);
          }
        }
      }
    }

    &.screen6 {
      display: flex;
      justify-content: center;
      pointer-events: auto;

      *:not(button) {
        pointer-events: none;
      }

      .content {
        background-image: url('../../../../assets/images/loaders/dotted-line.svg');
        background-position: 50% calc(50% - 70px);
        background-repeat: no-repeat;
        background-size: 90% auto;
        bottom: 0;
        height: 100%;
        max-width: $marginGeneric * 48;
        position: absolute;
        width: 100%;

        @media (orientation: landscape) and (max-height: $customHeight) {
          bottom: 12%;
        }
      }
    }

    &.skipped {
      padding-top: $marginGeneric * 4;
    }
  }
}

.brushForeGround,
.brushBackGround {
  pointer-events: none;
  opacity: 0;
  position: fixed;
  right: 0;
  top: calc(50% + $marginGeneric * 8);
  transform: translate3d(100%, -100%, 0);


  @media (orientation: landscape) and (max-height: $customHeight) {
    top: $marginGeneric * 30;
  }

  &.animate {
    animation: brushes 1.5s linear, brushesWobble 0.2s steps(2, end) infinite;
    opacity: 1;
  }
}

.brushForeGround {
  z-index: 2;

  &.animate {
    animation: brushes 1.5s linear, brushesWobble 0.2s steps(2, end) infinite reverse;
    animation-delay: 0.15s;
  }
}

.brushBackGround {
  z-index: -1;
}

@keyframes brushes {

  from {
    transform: translate3d(100%, -100%, 0);
  }

  to {
    transform: translate3d(calc(-100vw - 100%), -100%, 0);
  }
}

@keyframes brushesWobble {

  from {
    margin-top: 0;
  }

  to {
    margin-top: $marginGeneric * -0.5;
  }
}

.swipe {

  @include small;

  bottom: $marginGeneric * 10;
  display: flex;
  left: 50%;
  margin-bottom: 0;
  opacity: var(--loader-swipe-opacity, 0);
  position: fixed;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  z-index: 10;

  @include minTabletMobile {
    bottom: $marginGeneric * 12;
  }

  @media (orientation: landscape) and (max-height: $customHeight) {
    bottom: $marginGeneric * 9;
  }

  svg {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: slideright;
    margin-top: 2px;

    @keyframes slideright {

      0% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }

      25% {
        opacity: 1;
      }

      50%,
      100% {
        opacity: 0;
        transform: translate3d($marginGeneric, 0, 0);
      }
    }
  }
}
