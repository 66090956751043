@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers' as *;

.component {
  background-color: thc-color.$primary-white;
  color: thc-color.$primary-black;
  width: 100%;

  &.isVideo {
    background-color: thc-color.$primary-black;
  }

  > div {
    max-height: 100%;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
  }
}
