@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.subtitleIcon {
  align-items: center;
  display: flex;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.slideIn {
  opacity: 1;
  transform: translateX(0);
}

.slideOut {
  opacity: 0;
  transform: translateX(- thc-core.$space3);
}
