@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.skipTooltip {
  background-color: rgba(29, 29, 27, 90%);
  border-radius: $borderRadiusGeneric;
  color: $white;
  min-width: $marginGeneric * 30;
  opacity: 0;
  padding: $marginGeneric * 2;
  pointer-events: none;
  position: absolute;
  right: calc(100% + ($marginGeneric * 3));
  text-align: center;
  top: $marginGeneric * 4;
  transform: translate($marginGeneric * 6, calc(-50% + ($marginGeneric * 2)));
  transition: transform 0.2s linear, opacity 0.1s linear;

  &::after {
    background-color: rgba(29, 29, 27, 90%);
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    content: '';
    display: block;
    height: $marginGeneric * 3;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(100%, -50%);
    width: $marginGeneric * 2;
  }

  &.showTooltip {
    opacity: 1;
    transform: translate(0, calc(-50% + ($marginGeneric * 2)));
  }
}
