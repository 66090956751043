@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: thc-core.$space7 thc-core.$space4 thc-core.$space4;
}

.heading {

  @include thc-typography.heading3;
}

.content {
  flex-grow: 1;
  margin: thc-core.$space auto 0 auto;
  max-width: thc-core.$space * 74;

  .faults {
    display: flex;
    flex-direction: column;
    gap: thc-core.$space4;
    margin-bottom: thc-core.$space7;

    .faultsHeading {

      @include thc-typography.heading5;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: thc-core.$space3;
      margin-top: thc-core.$space3;

      li {

        @include thc-typography.regular;

        list-style: inside;
        margin-left: thc-core.$space2;
      }
    }
  }
}

.footer {
  margin-top: thc-core.$space7;
}
