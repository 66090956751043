@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@mixin landscape-or-ipad {

  @media screen and (min-width: thc-breakpoints.$breakpointTabletMobile), (orientation: landscape) {
    @content;
  }
}

.component {
  background-color: thc-color.$primary-white;
  min-height: calc(100vh - ($marginGeneric * 10));
  padding:
    thc-core.$space5 calc(thc-core.$space4 + env(safe-area-inset-left))
    thc-core.$space5 calc(thc-core.$space4 + env(safe-area-inset-right));
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: thc-breakpoints.$breakpointMobile) {
    margin: 0 auto;
    max-width: thc-core.$space * 64;

    @media (orientation: landscape) {
      margin-bottom: 0;
    }
  }

  .tipBtn {
    border: none;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    width: thc-core.$space * 14.75;
  }

  .description {

    @include thc-typography.large;

    padding-bottom: thc-core.$space5;
  }

  .retakePhoto {
    padding-bottom: thc-core.$space3;
  }
}

.photosWrapper {
  display: grid;
  grid-gap: $marginGeneric * 2;

  @include landscape-or-ipad {
    grid-template-columns: 1fr 1fr;

    > div {
      grid-template-columns: 1fr;
    }
  }
}

.divider {
  background-color: thc-color.$light-grey-2;
  border: none;
  display: none;
  height: 1px;
  margin: thc-core.$space * 2.5 0 thc-core.$space * 4.5;
  width: 100%;

  @include landscape-or-ipad {
    display: block;
  }
}

.infoBox {
  border: thc-core.$border-width solid thc-color.$light-grey-3;
  border-radius: thc-core.$border-radius;
  display: flex;
  margin-bottom: thc-core.$space4;
  padding: thc-core.$space4;

  p {
    padding: 0;
  }

  svg {
    --thc-icon-fill: #{thc-color.$primary-black};

    flex-shrink: 0;
    margin-right: thc-core.$space2;
  }
}
