@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  min-height: calc(100vh - thc-core.$space * 10);

  .desc {
    margin: 0 auto;
    max-width: thc-core.$space * 64;
    padding-bottom: thc-core.$space5;

    @include thc-typography.large;
  }
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: thc-core.$space3;
  margin: 0 auto;
  max-width: thc-core.$space * 64;

  .errorMessage {
    margin-top: thc-core.$space3;
  }

  .errorMessageOld {
    margin-top: thc-core.$space2;
  }

  .textareaBox {

    &.approved {
      margin-top: 0;
    }
  }

  .textareaBoxOld {
    margin-top: thc-core.$space7;

    &.approved {
      margin-top: 0;
    }
  }
}

.supStar {
  color: var(--thc-semantic-error);
  line-height: 1.2;
}

.warningBox {
  display: flex;
  flex-direction: column;
  gap: thc-core.$space2;

  .warningBoxContent {

    ul {
      list-style: disc;
      margin-left: thc-core.$space2 * 3.25;
    }
  }
}

.infoBoxButton {
  display: flex;
  justify-content: flex-end;
}
