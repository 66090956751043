@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: thc-color.$primary-white;
  display: flex;
  height: thc-core.$space6;
  justify-content: center;
  line-height: normal;
  margin-bottom: 0;
  padding: 0;
  text-indent: -9999px;
  touch-action: manipulation;
  width: thc-core.$space6;

  --thc-icon-accent-fill: #{$white};
  --thc-icon-size: #{thc-core.$space7};
}
