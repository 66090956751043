@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers.scss' as *;

.fromPad {

  @include fromPad;
}

.untilPad {

  @include untilPad;
}

.wrapper {
  display: flex;

  --thc-icon-fill: #{thc-color.$primary_blue};

  svg {
    margin-right: $marginGeneric;
  }
}
