@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
$customHeight: $marginGeneric * 53;

.component {
  display: flex;
  height: calc(100vh - ($marginGeneric * 8));
  justify-content: center;
  overflow: hidden;

  @media (orientation: landscape) {
    min-height: $marginGeneric * 54; // Give landscape breathing room
  }

  .circleItem {
    background-color: rgba(255, 255, 255, 60%);
    border: 4px solid $white;
    border-radius: 100%;
    display: block;
    padding-bottom: $marginGeneric * 50;
    position: absolute;
    top: 50%;
    width: 100%;
    max-width: $marginGeneric * 50;
    z-index: -1;
    transform: translateY(-65%) scale(var(--loader-screen4-circle, 0));
  }
}

.startButton {
  bottom: calc($marginGeneric * 9 + env(safe-area-inset-bottom));
  left: 50%;
  margin-bottom: 0;
  max-width: $marginGeneric * 50;
  opacity: var(--loader-button-opacity, 0);
  position: fixed;
  transform: translateX(-50%);
  transition: opacity 0.3s ease;
  width: calc(100% - ($marginGeneric * 6));
  z-index: 10;

  @include minTabletMobile {
    bottom: calc($marginGeneric * 13 + env(safe-area-inset-bottom));
  }

  @media (orientation: landscape) and (max-height: $customHeight) {
    bottom: calc($marginGeneric * 9 + env(safe-area-inset-bottom));
  }
}

.pagination {
  align-items: center;
  bottom: $marginGeneric * 4;
  display: var(--loader-pagination-display, flex);
  height: $marginGeneric;
  left: 50%;
  pointer-events: none;
  position: fixed;
  transform: translateX(-50%);
  z-index: 10;

  @include minTabletMobile {
    bottom: $marginGeneric * 6;
  }

  .item {
    background-color: rgba(thc-color.$dark-grey-3, 50%);
    border-radius: 50%;
    height: $marginGeneric;
    margin: 0 $marginGeneric;
    transform: scale(0.7);
    transition: transform 0.5s ease, background-color 0.5s ease;
    width: $marginGeneric;

    &.active {
      background-color: var(--thc-color-mid-grey);
      transform: scale(1);
    }
  }
}

.carousel {
  display: flex;
  height: calc(100% + 20px);
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  pointer-events: var(--loader-pointer-events, auto);
  -webkit-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  width: 100%;

  &.skipped {
    background-color: $white;
  }

  section {
    flex: none;
    height: 100%;
    max-width: 100%;
    -webkit-scroll-snap-align: start;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    width: 100%;
  }
}

.carImg {
  bottom: 50%;
  display: block;
  height: 50%;
  max-width: $marginGeneric * 50;
  pointer-events: none;
  position: absolute;
  width: 100%;

  @media (orientation: landscape) and (max-height: $customHeight) {
    bottom: 62%;
  }

  svg {
    bottom: 0;
    display: block;
    left: calc(50% - 145px);
    position: absolute;
    transition: transform 0.25s linear;
    width: 291px;
    z-index: 1;

    &.original {
      transform: scale(var(--loader-screen4-car));
    }

    &.dirty {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

      :global(.motorway-dirty-car) {
        fill: $grey_2;
      }
    }
  }

  .circle {
    align-items: center;
    display: flex;
    height: $marginGeneric * 15;
    left: 5%;
    position: absolute;
    bottom: 0;
    transform: translateY(-20%) scale(var(--loader-screen4-circle, 0));
    transition: transform 0.25s linear;
    width: 90%;

    em {

      @include title7;

      margin-bottom: 0;
      padding-bottom: $marginGeneric;
      position: absolute;
      text-align: center;
      top: calc(50% - $marginGeneric * 0.5);
      transform: translateY(-50%);
      width: 20%;
      z-index: 5;

      &:first-of-type {
        left: $marginGeneric * 2;

        &::before {
          background-position: top right;
          transform: rotate(180deg);
        }
      }

      &:last-of-type {
        right: $marginGeneric * 2;

        &::before {
          background-position: bottom right;
        }
      }

      &::before {
        background-image: url('../../../assets/images/loaders/right-arrow.svg');
        background-position: bottom left;
        background-repeat: no-repeat;
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }
  }
}
