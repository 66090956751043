@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  align-items: center;
  animation: showOn 0.2s;
  background-color: thc-color.$primary-white;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: calc(100vh - (thc-core.$component-height-large * 2));
  min-height: calc(100dvh - (thc-core.$component-height-large * 2));
  padding: thc-core.$space * 12 thc-core.$space4 thc-core.$space4;
  text-align: center;

  img {

    @media screen and (min-width: thc-breakpoints.$breakpointTabletMobile) {
      max-width: 50vw;
    }
  }

  h2 {
    padding-top: thc-core.$space * 8;

    @include thc-typography.heading3;
  }

  p {
    padding: thc-core.$space3 0;

    @media screen and (max-width: thc-breakpoints.$breakpointTinyMobile) {

      br {
        display: none;
      }
    }
  }

  @media (orientation: landscape) {
    padding-top: thc-core.$space3;

    h2 {
      padding-top: thc-core.$space3;
    }

    img {
      width: thc-core.$space * 25;
    }

    p {
      padding: thc-core.$space3;
    }
  }
}

.footer.footer {
  margin: 0 auto thc-core.$space2 auto;

  @media (orientation: landscape) {
    max-width: none;
    padding-left: thc-core.$space3;
    padding-right: thc-core.$space3;
  }
}

.footerInner {
  padding: 0 thc-core.$space3;
  width: 100%;

  @media (orientation: landscape) {
    padding: 0;
  }
}

@keyframes showOn {

  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
