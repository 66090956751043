@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.toast {
  width: $marginGeneric * 52;

  @include maxMobile {
    width: auto;
  }

  div:first-child {
    align-items: start;
  }

  h6 {

    @include thc-typography.heading6;
  }

  p {

    @include thc-typography.small;

    font-weight: normal;
    padding-bottom: 0;
  }

  svg {
    margin-left: $marginGeneric * 2;
    margin-top: $marginGeneric * 2;

    @include maxMobile {
      margin-left: 0;
    }
  }
}

.uploading {
  pointer-events: none;
}
