@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.component {
  display: flex;
  gap: thc-core.$space3;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: thc-core.$space5;

  h1 {

    @include thc-typography.heading3;
  }
}
