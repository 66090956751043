@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers' as *;

.toast {
  width: thc-core.$space * 52;

  @include maxMobile {
    width: auto;
  }

  div:first-child {
    align-items: start;
    text-align: center;
  }

  h6 {

    @include thc-typography.heading6;
  }

  p {

    @include thc-typography.small;

    font-weight: normal;
    padding-bottom: 0;

    a {
      border-bottom-color: thc-color.$primary-white;
      color: thc-color.$primary-white;
    }
  }

  svg {
    margin-left: thc-core.$space * 2;
    margin-top: thc-core.$space * 2;

    @include maxMobile {
      margin-left: 0;
    }
  }
}

.component {

  .camera {
    padding-bottom: thc-core.$space * 7;

    @include maxTabletMobile {
      padding-bottom: thc-core.$space * 4;
    }

    > svg {
      max-height: thc-core.$space * 27;

      @include maxTabletMobile {
        max-height: thc-core.$space * 23;
      }
    }
  }

  .cameraHide {
    display: none;
  }

  .strap {
    margin-bottom: thc-core.$space * 5;
    padding-bottom: 0;

    a {
      white-space: nowrap;
    }
  }

  form {
    margin: 0 auto;
    max-width: thc-core.$space * 50;

    .error {
      color: var(--thc-semantic-error);
      margin: thc-core.$space * -2 0 thc-core.$space;
    }

    .inputContainer {
      margin-bottom: thc-core.$space * 6;

      input:last-of-type,
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .emailQuickLink {
    margin-top: thc-core.$space * 3;

    p:last-of-type {

      @include title6;

      margin-bottom: 0;
      padding-bottom: thc-core.$space;
    }

    .emailIcon {

      @include genericborder;

      background-color: thc-color.$primary-white;
      height: thc-core.$space * 9;
      text-indent: -9999px;
    }
  }

  .emailIconSystemIOS {
    line-height: thc-core.$space * 9;
    text-align: center;

    .emailIcon {
      background-size: cover;
      display: inline-block;
      margin-left: thc-core.$space * 1.5;
      margin-right: thc-core.$space * 1.5;
      text-indent: -9999px;
      width: thc-core.$space * 9;
    }

    .emailIconMail { background-image: url('../../../assets/email_logos/iosMail.png'); }
    .emailIconYahoo { background-image: url('../../../assets/email_logos/yahoo.png'); }
    .emailIconGmail { background-image: url('../../../assets/email_logos/gmail.png'); }
    .emailIconOutlook { background-image: url('../../../assets/email_logos/outlook.png'); }
  }

  .emailIconSystemWeb {
    text-align: center;

    .emailIcon {
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: 80%;
      color: thc-color.$primary-black;
      cursor: pointer;
      display: inline-block;
      margin-left: thc-core.$space;
      padding: thc-core.$space;
      width: thc-core.$space * 24;
    }

    .emailIconYahoo { background-image: url('../../../assets/email_logos/yahoo-desktop.png'); }
    .emailIconGmail { background-image: url('../../../assets/email_logos/gmail-desktop.png'); }
    .emailIconOutlook { background-image: url('../../../assets/email_logos/outlook-desktop.svg'); }

    .emailIconAOL {
      background-image: url('../../../assets/email_logos/aol-desktop.svg');
      background-size: 40%;
    }

    .emailIconBTInternet { background-image: url('../../../assets/email_logos/bt-desktop.png'); }
    .emailIconSky { background-image: url('../../../assets/email_logos/sky-desktop.png'); }
  }
}
