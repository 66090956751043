@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
@use '../../../assets/scss/helpers' as *;

.component {
  left: 0;
  position: fixed;
  top: 0;
  transform: translate3d(0, -110%, 0);
  transition: transform 0.2s linear;
  width: 100%;
  z-index: 200;

  &.show {
    transform: translate3d(0, 0, 0);
  }

  .componentContent {
    align-items: center;
    display: flex;
    min-height: $marginGeneric * 7;
    padding-bottom: 0;
    padding-top: 0;
  }

  &.refresh {

    .componentContent {

      @include containerPadding;

      background-color: rgba(thc-color.$primary-black, 0.7);
      color: $white;
      padding-bottom: $marginGeneric * 2;
      padding-top: $marginGeneric * 2;

      @media screen and (min-width: $breakpoint-iPad) { // iPad
        padding-bottom: $marginGeneric * 3;
        padding-top: $marginGeneric * 3;
      }
    }

    .toastContent {
      display: flex;
      flex-wrap: wrap;

      h4 {

        @include title6;

        margin-bottom: 0;
      }

      .link {
        border-color: thc-color.$primary-white;
        color: thc-color.$primary-white;
      }
    }
  }
}
