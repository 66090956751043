@use "_index.scss" as *; @use "sass:math"; @use "~@THC/styles/color" as thc-color; @use "~@THC/styles/core" as thc-core; @use "~@THC/styles/typography" as thc-typography; @use "~@THC/styles/breakpoints" as thc-breakpoints;
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $white;
  overflow-y: auto;
  background-color: var(--thc-color-off-black);
  z-index: 999;
  transform: translate3d(0, 0, 0);
  padding: $marginGeneric * 6 $marginGeneric * 5 $marginGeneric * 5;

  .list {
    margin-top: $marginGeneric;
  
    li {
      margin-bottom: $marginGeneric * 3;
  
      :global(.motorway-svg-fill) {
        fill: $white;
      }

      :global(.motorway-svg-stroke) {
        stroke: var(--thc-color-off-black);
      }
    }
  }
}

.block {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close {
	border: none;
	color: inherit;
	font: inherit;
	outline: inherit;
	padding: 0;
  --thc-icon-fill: rgba(255, 255, 255, 0.3);
  background: none;
  cursor: pointer;
  height: $marginGeneric * 3;
  position: absolute;
  right: $marginGeneric * 3;
  top: $marginGeneric * 3;
  width: $marginGeneric * 3;
}

.title {

  @include title3;
  display: flex;
  gap: $marginGeneric * 2;
  text-align: center;
  margin-bottom: $marginGeneric * 4;

  svg {
    flex-shrink: 0;
  }
}

.footer {
  border-top: 1px solid var(--thc-color-dark-grey);
  margin-top: $marginGeneric * 6;
  padding-top: $marginGeneric * 6;

  a:not(.mw-i) {
    color: $white;
    text-decoration: underline;
  }
}
